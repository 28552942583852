import 'isomorphic-fetch'
import * as Raven from 'raven-js'

const MAX_FETCH_RETRIES = 3
const DELAY_AFTER_FETCH_RETRY = 100
const HTTP_STATUS_OK = 200

export const fetchRetry = (url?: Request | string, options?: RequestInit): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const wrappedFetch = retries => {
      fetch(url, options)
        .then(response => {
          if (response.status === HTTP_STATUS_OK) { //tslint:disable-line
            resolve(response)
          } else {
            if (retries > 0) {
              retry(retries)
            } else {
              response.json().then(err => {
                Raven.setExtraContext(err)
                reject(new Error(`Fetch failed with status ${response.status}`))
              }).catch(() => {
                reject(new Error(`Fetch failed with status ${response.status}`))
              })
            }
          }
        })
        .catch(error => {
          if (retries > 0) {
            retry(retries)
          } else {
            reject(error)
          }
        })
    }

    const retry = retries => {
      setTimeout(() => {
        wrappedFetch(--retries)
      }, DELAY_AFTER_FETCH_RETRY)
    }

    wrappedFetch(MAX_FETCH_RETRIES)
  })
}
