import translations from '../services/translations'
import * as _ from 'lodash'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import { AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '../../../constants/plugins'
import { createGlobalDesignManifest, DESIGN_GROUPS } from './global-design-manifest'
import { getBaseUrl } from '../../../utils/utils'
import { COMPONENT_TYPES } from '../preset/fields/component-types'

const getDisplayName = (plugins: FormPlugin[] = []) => {
  if (plugins.includes(FormPlugin.REGISTRATION_FORM)) {
    return 'registrationFormBuilder'
  } else if (isGetSubscribers(plugins)) {
    return 'getSubscribersFormBuilder'
  }

  return 'formBuilder'
}

const isGetSubscribers = (plugins: FormPlugin[] = []) =>
  plugins.includes(FormPlugin.GET_SUBSCRIBERS)

const getHelpId = (plugins: FormPlugin[] = []) => {
  return plugins.includes(FormPlugin.REGISTRATION_FORM)
    ? GFPP.HELP_ID.REGISTRATION_FORM_CONTAINER
    : GFPP.HELP_ID.FORM_CONTAINER
}

export const createBoxManifest = ({ isTopPremium, plugins, withGlobalDesign, duplicatable = false, showNewPresets = false }: AppStateObject = {}) => {
  const displayName = getDisplayName(plugins)
  const extraIconButtons = isTopPremium
    ? {}
    : {
        [GFPP_ACTIONS.UPGRADE]: GFPP.KEEP_DEFAULT,
      }
  const baseUrl = `https://${getBaseUrl()}`
  const gloablDesignManifest = {
    gfpp: {
      desktop: {
        globalDesign: {
          ...createGlobalDesignManifest(showNewPresets, `${baseUrl}/assets/images`),
        },
      },
    },
    designMapping: {
      [COMPONENT_TYPES.FORM_CONTAINER]: { [DESIGN_GROUPS.FORM_BACKGROUND]: ['bg'] },
    },
  }
  const formDeisgnManifest = {
    gfpp: {
      desktop: {
        iconButtons: {
          [GFPP_ACTIONS.DESIGN]: {
            actionId: GFPP_IDS.FORM_STYLE,
          },
        },
      },
    },
  }
  const manifest = {
    showFocusBox: true,
    displayName: translations.t(displayName),
    behavior: { duplicatable },
    gfpp: {
      desktop: {
        mainAction1: {
          actionId: GFPP_IDS.MANAGE_FIELDS,
          label: translations.t('manageFields.gfppTitle'),
        },
        mainAction2: isGetSubscribers(plugins)
          ? {
              actionId: GFPP_IDS.MANAGE_SUBSCRIBERS,
              label: translations.t('manageSubscribers.gfppTitle'),
            }
          : GFPP.REMOVE,
        iconButtons: {
          [GFPP_ACTIONS.SETTINGS]: {
            actionId: GFPP_IDS.FORM_SETTINGS,
          },
          [GFPP_ACTIONS.LAYOUT]: {
            actionId: GFPP_IDS.FORM_LAYOUT,
          },
          [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
          ...extraIconButtons,
        },
        helpId: getHelpId(plugins)
      },
      mobile: {
        /* use default configurations */
        helpId: GFPP.HELP_ID.FORM_CONTAINER_MOBILE,
      },
    },
  }
  return withGlobalDesign
    ? _.merge(manifest, gloablDesignManifest)
    : _.merge(manifest, formDeisgnManifest)
}
