import * as _ from 'lodash'
import { isProduction } from '../../utils/utils'
import * as axios from 'axios'
import { wixAxiosInstanceConfig } from '@wix/wix-axios-config'

const formsBaseUrl = () => (isProduction() ? 'editor.wix.com/wix-form-builder' : 'localhost:3001')
const hasQuery = (uri: string) => _.includes(uri, '?')
const getContactsUrl = () => `https://${formsBaseUrl()}/contacts`

const FORMS_URL = 'https://editor.wix.com/_api/wix-form-builder-web'
const PLATFORMIZED_FORMS_URL = `${FORMS_URL}/v1`
const CASHIER_URL = 'https://cashier-services.wix.com/_api/payment-paybutton-web/paybutton/v2'

export default class RemoteApi {
  private boundEditorSDK
  private axiosInstance
  private experiments

  constructor({ boundEditorSDK, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.experiments = experiments
    this.axiosInstance = wixAxiosInstanceConfig(axios, { baseURL: '/' })
  }

  public getOwnerEmail = async () => this._get(getContactsUrl(), 'get-owner-email')
  public getEmailById = async emailId => this._get(getContactsUrl(), `get-email?emailId=${emailId}`)
  public getTags = async () => this._get(getContactsUrl(), 'get-tags')
  public createTag = async tagName => this._post(getContactsUrl(), 'create-tag', { tagName })
  public updateTag = async (tagId, newName) =>
    this._post(getContactsUrl(), 'update-tag', { tagId, newName })
  public getCustomFields = async () => this._get(getContactsUrl(), 'get-custom-fields')

  public insertEmail = async email => this._post(getContactsUrl(), 'insert-email', { email })
  public createCustomField = async field =>
    this._post(getContactsUrl(), 'create-custom-field', { field })
  public publishSite = data => this._axiosPost(PLATFORMIZED_FORMS_URL, 'publish-site', data)
  public editDraft = form => this._axiosPost(PLATFORMIZED_FORMS_URL, 'edit-draft', { form: form })
  public getPremiumRestrictions = () => this._get(FORMS_URL, 'premium/restrictions')

  public createProduct = (data: CreateProductRequest) => this._axiosPost(CASHIER_URL, 'products', data).then(res => res.data)

  private _get = (url, endpoint) => this._request({ url, endpoint, method: 'GET' })
  private _post = (url, endpoint, data) => this._request({ url, endpoint, method: 'POST', data })

  private _axiosPost = async (url, endpoint, data) => {
    if (this.experiments.enabled('specs.cx.FormBuilderDisableTemplateRequests')) {
      const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())
      if (isTemplate) {
        return Promise.resolve()
      }
    }

    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    return this.axiosInstance.post(`${url}/${endpoint}`, data, {
      headers: { Authorization: appInstance },
    })
  }

  private async _request({ url, endpoint, method, data = undefined }) {
    const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    const response = !isTemplate
      ? await axios.request({
          headers: { 'X-Wix-With-Static-Templates': 'true' },
          method,
          url: `${url}/${endpoint}${hasQuery(endpoint) ? '&' : '?'}instance=${appInstance}`,
          data,
        })
      : null
    return _.get(response, 'data')
  }
}
