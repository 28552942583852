import {
  wixCodeItemsToProGallery,
  proGalleryItemsToWixCode,
  proGalleryItemToWixCode,
} from '@wix/pro-gallery-items-formatter/dist/src/wixCode';

class WixCodeApi {
  constructor() {
    this.generateApi = this.generateApi.bind(this);
    this.onItemChanged = this.onItemChanged.bind(this);
    this.onItemClicked = this.onItemClicked.bind(this);
    this.setStyleParams = this.setStyleParams.bind(this);
    this.setClickCallback = this.setClickCallback.bind(this);
    this.setItemChangedCallback = this.setItemChangedCallback.bind(this);

    this.manualStyleParams = {};
    this.itemClickedCallbacks = [];
    this.itemChangedCallbacks = [];
  }

  setClickCallback(callback) {
    if (typeof callback === 'function') {
      this.itemClickedCallbacks.push(callback);
    }
  }

  onItemClicked(itemProps) {
    const _item = {
      compId: this.compId,
      event: {
        action: 'itemClicked',
        id: itemProps.id,
        imageIndex: itemProps.idx,
        item: {
          uri: itemProps.originalsUrl,
          description: itemProps && itemProps.description,
          alt: itemProps && itemProps.title,
          title: itemProps && itemProps.title,
          height: itemProps && itemProps.style && itemProps.style.height,
          width: itemProps && itemProps.style && itemProps.style.width,
          link: itemProps && itemProps.linkUrl,
          target: itemProps && itemProps.linkOpenType,
        },
      },
    };

    //activate open callbacks
    try {
      for (let i = 0; i < this.itemClickedCallbacks.length; i++) {
        const callback = this.itemClickedCallbacks[i];
        if (typeof callback === 'function') {
          callback(_item);
        }
      }
    } catch (e) {
      console.warn('Gallery failed to activate onItemClicked callbacks', e);
    }
  }

  setItemChangedCallback(callback) {
    if (typeof callback === 'function') {
      this.itemChangedCallbacks.push(callback);
    }
  }

  onItemChanged(item) {
    try {
      const _item = {
        compId: this.compId,
        event: {
          action: 'imageChanged',
          id: item.id,
          imageIndex: item.idx,
          item: proGalleryItemToWixCode(item),
        },
      };
      for (let i = 0; i < this.itemChangedCallbacks.length; i++) {
        const callback = this.itemChangedCallbacks[i];
        if (typeof callback === 'function') {
          callback(_item);
        }
      }
    } catch (e) {
      //
    }
  }

  setStyleParams(styleParams) {
    const mapStyleParams = styleParams => {
      const mappedStyleParams = {};
      const keys = Object.keys(styleParams);
      for (const key of keys) {
        mappedStyleParams[key] = mapStyleParam(key, styleParams[key]);
      }
      return mappedStyleParams;
    };

    const mapStyleParam = (key, val) => {
      let newVal;
      switch (key) {
        case 'itemClick':
          switch (val) {
            default:
            case 'none':
            case 'disabled':
              newVal = 'nothing';
              break;
            case 'expand':
            case 'zoomMode':
              newVal = 'expand';
              break;
            case 'link':
            case 'goToLink':
              newVal = 'link';
              break;
          }
          break;
        default: {
          newVal = val;
        }
      }
      return newVal;
    };

    try {
      this.manualStyleParams = mapStyleParams(styleParams);
    } catch (e) {
      console.error('Failed to set styleParams', e);
      return false;
    }
  }

  generateApi({ proGalleryStore, setNewItems }) {
    const {
      setStyleParams,
      manualStyleParams,
      setClickCallback,
      setItemChangedCallback,
    } = this;

    return {
      get type() {
        return '$w.Gallery';
      },
      get galleryCapabilities() {
        return {
          isPlayable: false,
          hasCurrentItem: false,
          hasNavigationButtons: false,
          supportsAllMediaTypes: true,
        };
      },
      set items(images) {
        proGalleryStore.setItems(wixCodeItemsToProGallery(images));
        proGalleryStore.totalItemsCount = images.length;
        proGalleryStore.itemsSrc = 'wixCode';
        const proGalleryStoreItems = proGalleryStore.getItems();
        setNewItems(
          proGalleryStoreItems
            .filter(item => !!item.mediaUrl)
            .map(item => {
              const newItem = {
                ...item,
                metaData: JSON.stringify(item.metaData),
              };
              return newItem;
            }),
        );
      },
      get items() {
        return proGalleryItemsToWixCode(proGalleryStore.getItems());
      },
      onItemClicked: callback => setClickCallback(callback),
      onCurrentItemChanged: callback => setItemChangedCallback(callback),
      set clickAction(action) {
        setStyleParams({ itemClick: action }); //action is: 'none' / 'expand' / 'link'
      },
      get clickAction() {
        return manualStyleParams.itemClick;
      },
      toJSON: () => ({
        items: proGalleryStore.getItems(),
        clickAction: manualStyleParams.itemClick,
      }),
    };
  }
}

export default new WixCodeApi();
