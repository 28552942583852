import * as _ from 'lodash'
import { FormPlugin } from '../../../constants/plugins'

export interface AppStateObject {
  isTopPremium?: boolean
  plugins?: FormPlugin[]
  withGlobalDesign?: boolean
  duplicatable?: boolean
  showNewPresets?: boolean
}

export class AppStateBuilder {
  constructor(private stateObject: AppStateObject = {}) {
  }

  get = () => this.stateObject
  toString = () =>
    Object.keys(this.stateObject)
      .filter(k => this.stateObject[k])
      .map(k => _.isArray(this.stateObject[k]) ? this.stateObject[k].join('-') : k)
      .sort()
      .join('-')
}
