import * as _ from 'lodash'
import { ComponentRef } from '../api-types'
import CoreApi from '../core-api'
import RemoteApi from '../../../panels/commons/remote-api'
import { AppStateBuilder } from './app-state-builder'
import { ROLE_FORM } from '../../../constants/roles'
import { FormPlugin } from '../../../constants/plugins'

export default class AppState {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private experiments: any
  private remoteApi: RemoteApi

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  private async _isTopPremium() {
    const { restrictions } = await this.coreApi.premium.getPremiumRestrictions()
    return restrictions.isTopPremium
  }

  private async _getFormPlugins(formRef: ComponentRef) {


    const { plugins } = await this.coreApi.getFormConfigData(formRef)
    if (_.isEmpty(plugins)) {
      return [FormPlugin.FORM_BUILDER]
    }
    return plugins
  }

  private _isWithGloablDesign() {
    return this.experiments.enabled('specs.cx.FormBuilderGlobalDesign')
  }

  public async setState(controllerRefs?: ComponentRef[]) {
    if (!controllerRefs) {
      const controllers: {
        controllerRef: ComponentRef
      }[] = await this.boundEditorSDK.controllers.listAllControllers()
      controllerRefs = controllers.map(({ controllerRef }) => controllerRef)
    }

    const isTopPremium = await this._isTopPremium()
    const withGlobalDesign = this._isWithGloablDesign()
    const showNewPresets = this.experiments.enabled('specs.cx.FormBuilderNewThemes')
    const fetchThemesFromCDN = this.experiments.enabled('specs.cx.FormBuilderFetchThemesFromCDN')

    controllerRefs.forEach(async ref => {
      const formRef = await this.coreApi.findConnectedComponent(ref, ROLE_FORM)

      if (!formRef) {
        return
      }

      const plugins = await this._getFormPlugins(formRef)
      const duplicatable = await this.coreApi.isAppWidget(ref)

      const stateBuilder = new AppStateBuilder({
        isTopPremium,
        plugins,
        withGlobalDesign,
        duplicatable,
        showNewPresets: fetchThemesFromCDN && showNewPresets,
      })

      const stateName = stateBuilder.toString()

      if (stateName) {
        await this.boundEditorSDK.controllers.setState({ state: { [stateName]: [ref] } })
      }
    })
  }
}
