import * as _ from 'lodash'
import { FormStrategy } from './form-strategy'
import { FIELDS, ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG } from '../../constants/roles'
import { FieldPreset } from '../../constants/field-types'
import { escapeRegExp, innerText } from '../viewer-utils'
import { CRM_TYPES } from '../../constants/crm-types-tags';

const ERROR_COLOR = '#FF4040'
const PASSWORD_LENGTH = { MIN: 4, MAX: 100 }

const getFields = $w => {
  const email: any = _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL}`)).first()
  const password: any = _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD}`)).first()
  return { email, password }
}

const submitFields = fields =>
  fields.filter(field => _.get(field, 'connectionConfig.fieldType') !== 'password')

const ignoreFields = {
  [FieldPreset.REGISTRATION_FORM_PASSWORD]: true,
  [FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: true,
  [FieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: true,
  [FieldPreset.REGISTRATION_FORM_LOGIN_EMAIL]: true,
}

const showExistingAccountMessage = (message, { messageContent }) => {
  if (!_.get(message, 'html')) {
    return
  }

  const colorRegExp = /color: ?[^;"]+/
  let htmlErrorMessage = messageContent

  if (message.html.indexOf(colorRegExp) === -1) {
    htmlErrorMessage = `<span style="color: ${ERROR_COLOR}">${htmlErrorMessage}</span>`
  }

  message.html = message.html
    .replace(colorRegExp, `color: ${ERROR_COLOR}`)
    .replace(new RegExp(`>${escapeRegExp(innerText(message.html))}`), `>${htmlErrorMessage}`)

  message.show()
}

const filterFields = fields =>
  fields.filter(field => {
    const { crmType, fieldType } = field.connectionConfig
    return crmType && _.isString(field.value) && !ignoreFields[fieldType]
  })

export class RegistrationFormStrategy extends FormStrategy {
  static isEnabled($w) {
    const { email, password } = getFields($w)
    return email && password
  }

  constructor(submitArgs, initInstance, linksUtil) {
    super(submitArgs, initInstance, linksUtil)
    const { $w, wixUsers, wixWindow } = submitArgs
    const [$loginLink] = $w(`@${ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG}`)

    const [$password] = $w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD}`)

    $password.onCustomValidation((value, reject) => {
      if (value.length < PASSWORD_LENGTH.MIN || value.length > PASSWORD_LENGTH.MAX) {
        reject(`Password length must be between ${PASSWORD_LENGTH.MIN} and ${PASSWORD_LENGTH.MAX} characters.`)
      }
    });

    // tslint:disable-next-line:early-exit
    if ($loginLink) {
      $loginLink.onClick(() =>
        wixUsers
          .promptLogin({ mode: 'login' })
          .then(() => wixWindow.lightbox.close())
          .catch(() => wixWindow.lightbox.close())
      )
    }
  }

  async execute({ attachments, fields }) {
    const { $w, wixUsers, wixWindow, $message } = this.submitArgs
    const { email, password } = getFields($w)
    const validFields = filterFields(fields)
    const contactInfo = validFields.reduce((contactInfo, field) => {
      const { crmType, customFieldId, crmLabel } = field.connectionConfig
      if (crmType === CRM_TYPES.CUSTOM_FIELD) {
        if (customFieldId) {
          contactInfo[crmLabel] = field.value
        }
      } else {
        contactInfo[crmType] = field.value
      }

      return contactInfo
    }, {})

    try {
      await wixUsers.register(email.value, password.value, { contactInfo, defaultFlow: true })
      wixWindow.lightbox.close()
    } catch (e) {
      await showExistingAccountMessage($message, {
        messageContent: 'A member with this email address already exists.',
      })
      return
    }
    return super.execute({ attachments, fields: submitFields(validFields), skipSendActivity: true })
  }

  async postSubmission() {
    const { wixWindow } = this.submitArgs
    setTimeout(() => wixWindow.lightbox.close(), 750)
  }
}
