import * as _ from 'lodash'
import { createForm } from '../services/form-service'
import { createSuffixedName } from '../../../utils/utils'
import { SHOULD_CREATE_COLLECTION } from '../preset/presets-data'
import translations from '../services/translations'
import { undoable } from '../utils'
import { FormPreset } from '../../../constants/form-types'
import { EVENTS, ORIGINS } from '../../../constants/bi'
import { ComponentRef } from '../api-types'
import { ROLE_FORM } from '../../../constants/roles'
import CollectionsApi from '../collections/api'
import CoreApi from '../core-api'
import { Theme } from '../../../constants/form-style'
import { APP_CONTROLLER_DEFINITION, APP_WIDGET_DEFINITION } from './controller-definition'

const MASTER_PAGE = { type: 'DESKTOP', id: 'masterPage' }
const ADI_INITIATOR = 'ADI'

const normalizeFormName = (
  formNames: string[],
  nameFromPreset: string | undefined,
  presetKey: FormPreset
) => {
  const title =
    nameFromPreset ||
    translations.t('formName', {
      name: translations.t(`addForm.templates.${presetKey}.label`),
    })

  return createSuffixedName(formNames, title)
}

export default class AddFormApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private collectionsApi: CollectionsApi
  private experiments: any

  constructor(boundEditorSDK, coreApi, collectionsApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.collectionsApi = collectionsApi
    this.experiments = experiments
  }

  @undoable()
  public async addForm(
    presetKey: FormPreset,
    {
      containerRef = null,
      targetPageRef = null,
      coords = null,
      theme = null,
      source_name = ORIGINS.APP_MARKET,
    } = {}
  ) {
    const shouldFetchPresetsFromCDN = this.experiments.enabled(
      'specs.cx.FormBuilderFetchPresetsFromCDN'
    )
    const themeInitial = !theme && source_name === ORIGINS.APP_MARKET ? Theme.THEME01 : theme
    if (!containerRef) {
      await this.coreApi.saveSiteIfUnsaved()
    }

    const locale = await this.boundEditorSDK.info.getLanguage()
    const { plugin, box, fields } = await Promise.resolve(
      createForm(
        presetKey,
        {
          coords,
          theme: themeInitial,
          locale,
          shouldFetchPresetsFromCDN,
        },
        reason => this.coreApi.logFetchPresetsFailed(null, reason)
      )
    )

    this._firstTimePanelHandler(plugin)

    const formName = await this._getFormName(_.get(box, 'connectionConfig.formName'), presetKey)
    let formLabelId = ''

    try {
      formLabelId = await this._createTag(formName)
    } catch (ex) {}

    const [pageRef, msid] = await Promise.all([
      targetPageRef || this.boundEditorSDK.pages.getCurrent(),
      this.coreApi.getMetaSiteId(),
    ])

    let emailId = ''

    try {
      emailId = await this.coreApi.getOwnerEmailId()
    } catch (err) {}

    const containerDefinition = _.merge({}, box, {
      connectionConfig: {
        formName,
        msid,
        theme: themeInitial,
        formLabelId,
        emailId,
        labels: [...box.connectionConfig.labels, formLabelId],
      },
    })

    let controllerRef
    const initiator = this.coreApi.initiator()
    const isAppWidgetEnabled = this.experiments.enabled('specs.cx.FormBuilderAppWidget') && !_.isEmpty(initiator) && initiator !== ADI_INITIATOR
    if (isAppWidgetEnabled) {
      let containerOfForm
      if (containerRef) {
        const containerLayout = await this.boundEditorSDK.components.layout.get({
          componentRef: containerRef,
        })
        _.merge(containerDefinition.data.layout, _.pick(containerLayout, ['x', 'y']))

        const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef: containerRef })
        containerOfForm = _.first(ancestors)
      } else {
        containerOfForm = pageRef
      }

      controllerRef = await this._addAppWidget(containerOfForm, containerDefinition)
    } else {
      controllerRef = await this._addController(pageRef)
    }
    this.coreApi.appState.setState([controllerRef])

    let formRef
    if (containerRef && !isAppWidgetEnabled) {
      await this.coreApi.connect(containerDefinition, controllerRef, containerRef)

      this._removeLoader(containerRef)

      const {
        data: {
          layout: { width, height },
        },
      } = box

      if (box.connectionConfig.preset === FormPreset.REGISTRATION_FORM) {
        await this.coreApi.layout.centerComponentInsideLightbox(containerRef)
      } else {
        this.boundEditorSDK.components.layout.update({
          componentRef: containerRef,
          layout: { height, width },
        })
      }
      formRef = containerRef
    } else {
      if (isAppWidgetEnabled) {
        const components = await this.boundEditorSDK.controllers.getControllerConnections({
          controllerRef,
        })
        formRef = components[0].componentRef
        if (!containerDefinition.data.layout.y) {
          await this._fixFormY(controllerRef)
        }
      } else {
        formRef = (await this.coreApi.addComponentAndConnect(
          containerDefinition,
          controllerRef,
          pageRef
        )).connectToRef
        await this._fixFormY(formRef)
      }
    }

    this.biLogger.log({
      evid: EVENTS.PANELS.addFormPanel.CHOOSE_TEMPLATE,
      template: presetKey,
      form_comp_id: formRef.id,
      source_name,
    })

    const fieldsData = await Promise.all(
      fields.map(async field => {
        const { role, connectionConfig } = await this.coreApi.addComponentAndConnect(
          _.merge({}, field, {
            connectionConfig: {
              collectionFieldKey: _.camelCase(field.connectionConfig.crmLabel),
            },
          }),
          controllerRef,
          formRef
        )
        return _.merge({ role }, connectionConfig)
      })
    )

    this.boundEditorSDK.selection.selectComponentByCompRef({
      compsToSelect: [isAppWidgetEnabled ? controllerRef : formRef],
    })

    if (containerRef && isAppWidgetEnabled) {
      this.boundEditorSDK.components.remove({ componentRef: containerRef })
    }

    if (!containerRef) {
      this._createAutoCollection(formRef, presetKey, fieldsData).then(collectionId => {
        this.coreApi.editDraft(formRef, formName, collectionId)
        this.coreApi.saveSite()
      })
    } else {
      this.coreApi.editDraft(formRef, formName)
    }

    return formRef
  }

  private _firstTimePanelHandler = plugin => {
    const plugins = plugin.map(plugin => plugin.id)
    if (plugins && _.includes(plugins, 'get-subscribers')) {
      this.coreApi.managePanels.openGetSubscribersFirstTimePanel()
    }
  }

  private async _addController(pageRef) {
    const appDefinitionId = await this.boundEditorSDK.info.getAppDefinitionId()
    const pageData = await this.boundEditorSDK.components.data.get({
      componentRef: pageRef,
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: _.merge({}, APP_CONTROLLER_DEFINITION, {
        data: {
          applicationId: appDefinitionId,
        },
      }),
      pageRef: _.get(pageData, 'isPopup') ? pageRef : MASTER_PAGE,
    })
  }

  private async _addAppWidget(pageRef, containerDefinition) {
    const appDefinitionId = await this.boundEditorSDK.info.getAppDefinitionId()
    const dataItemIdPlaceholder = 'data_item_id_placeholder'

    const container = _.merge({}, containerDefinition.data, {
      components: [],
      connections: {
        type: 'ConnectionList',
        items: [
          {
            type: 'ConnectionItem',
            role: ROLE_FORM,
            controllerId: dataItemIdPlaceholder,
            isPrimary: true,
            config: JSON.stringify(containerDefinition.connectionConfig),
          },
        ],
      },
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: _.merge({}, APP_WIDGET_DEFINITION, {
        data: {
          applicationId: appDefinitionId,
          id: dataItemIdPlaceholder,
        },
        layout: containerDefinition.data.layout,
        components: [container],
      }),
      pageRef,
    })
  }

  private async _fixFormY(componentRef: ComponentRef): Promise<void> {
    const { height, y } = await this.boundEditorSDK.components.layout.get({
      componentRef,
    })
    this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { y: _.max([0, y - height / 2]) },
    })
  }

  private async _createAutoCollection(
    componentRef: ComponentRef,
    presetKey: FormPreset,
    fieldsData
  ): Promise<string | null> {
    const shouldCreateCollection = SHOULD_CREATE_COLLECTION[presetKey]

    if (!shouldCreateCollection) {
      return Promise.resolve(null)
    }

    const startCollectionBi = {
      template: presetKey,
      form_comp_id: componentRef.id,
      request_type: 'auto',
    }
    const endCollectionBi = {
      template: presetKey,
      form_comp_id: componentRef.id,
      request_type: 'auto',
    }

    const collectionId = await this.collectionsApi.createCollection(
      { preset: presetKey },
      { startBi: startCollectionBi, endBi: endCollectionBi }
    )
    if (!collectionId) {
      return null
    }

    return Promise.all([
      this.coreApi.setComponentConnection(componentRef, {
        collectionId: `${componentRef.id}_${collectionId}`,
      }),
      this.collectionsApi.addFieldsToCollection(
        collectionId,
        fieldsData,
        (fieldComponent, fieldKey) =>
          this.coreApi.setComponentConnection(fieldComponent, { collectionFieldKey: fieldKey })
      ),
    ]).then(() => collectionId)
  }

  private async _getFormName(nameFromPreset, presetKey): Promise<string> {
    const controllers: any[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formNames = await Promise.all(
      controllers.map(async ({ controllerRef }) => {
        const formRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
        const {
          config: { formName },
        } = formRef
          ? await this.coreApi.getComponentConnection(formRef)
          : { config: { formName: '' } }
        return formName
      })
    )

    return normalizeFormName(formNames, nameFromPreset, presetKey)
  }

  private async _createTag(formName: string): Promise<string | undefined> {
    return (await this.coreApi.createTag(formName)).id
  }

  private async _removeLoader(componentRef): Promise<void> {
    return this.boundEditorSDK.components.style.update({
      componentRef: componentRef,
      style: { loader: 0 },
    })
  }
}
