import { FieldPreset } from '../../constants/field-types'
import { SuccessActionTypes } from '../../constants/success-settings'
import { FormPreset } from '../../constants/form-types'
import { FormPlugin } from '../../constants/plugins'
import { Theme } from '../../constants/form-style'
import { COMPONENT_TYPES } from './preset/fields/component-types'

export interface FormField {
  componentRef: ComponentRef
  crmLabel: string
  required: boolean
  crmType: string
  crmTag: string
  fieldType: FieldPreset
  customFieldId?: string
  collectionFieldKey?: string
  collectionFieldType?: string
  checked?: boolean
  role: string
  label: string
  placeholder: string
  showLabel: boolean
  buttonLabel?: string,
  x?: number,
  y?: number,
  height?: number,
  width?: number
}

export interface FormFieldDefinition {
  fieldId: string
  fieldName: string
  fieldCollectionType?: string
  fieldCollectionKey?: string
}

export type ComponentRef = {
  id: string
  type: string
}

export type formComponent = {
  presetKey: string
  rawComp: any
  theme?: Theme
  newSuccessMessage?: string
}

export type Plugin = {
  id: FormPlugin
}

export interface ProductData {
  productId: string
  name: string
  price: string
}

export type ComponentConfig = {
  formName: string
  preset: FormPreset
  fieldType: FieldPreset
  plugins?: Plugin[]
  collectionId?: string
  successActionType: SuccessActionTypes
  labels: string[]
  successLinkValue: any
  theme?: Theme
  emailId?: string
  secondEmailId?: string
  formLabelId?: string
  collectionFieldKey?: string
  crmLabel: string
  crmType: string
  crmTag: string
  customFieldId?: string
  collectionFieldType?: string
  label: string
  product?: ProductData
}

export type ComponentConnection = {
  role: string
  config: ComponentConfig
  controllerRef: ComponentRef
  isPrimary: boolean
}

export type ColorPalette = {
  colorsPalette: Array<Array<string>>
  colors: object
}

export enum CriticalComponentTypes {
  SUBMIT_BUTTON = 'submitButton',
  SIGNUP_BUTTON = 'signupButton',
  HIDDEN_MESSAGE = 'hiddenMessage',
  REGISTRATION_FORM_MESSAGE = 'registrationFormMessage',
  EMAIL_FIELD = 'emailField',
}

export type FormSnapshot = {
  components: ComponentSnapshot[]
  config: ComponentConfig
}

export type ComponentSnapshot = {
  role: string
  config: ComponentConfig
  data: any
  props: any
  layout: any,
  componentRef: ComponentRef,
  style: any,
  componentType: COMPONENT_TYPES,
  components: ComponentSnapshot[] | undefined,
  skin: string
}
