import * as _ from 'lodash'
import * as submitButtonStructure from '../../../assets/presets/submit-button.json'
import * as signupButtonStructure from '../../../assets/presets/signup-button.json'
import * as hiddenMessageStructure from '../../../assets/presets/hidden-message.json'
import * as registrationFormMessageStructure from '../../../assets/presets/registration-form-message.json'
import { ButtonType, MessageType } from '../../../constants/field-types'
import { fetchPreset } from '../services/form-service'
import { ROLE_SUBMIT_BUTTON, ROLE_MESSAGE } from '../../../constants/roles'

const getComponentFromPreset = async (
  role: string,
  { presetKey, shouldFetchPresetsFromCDN, locale, onFailedPresetCallback }
) => {
  if (!presetKey) return
  const rawPreset = await fetchPreset(
    presetKey,
    shouldFetchPresetsFromCDN,
    locale,
    onFailedPresetCallback
  )
  if (!rawPreset) return
  const components = rawPreset.components
  return _.find(components, comp => comp.role === role)
}

export const getSubmitButtonPreset = async (
  buttonType,
  { presetKey, shouldFetchPresetsFromCDN, locale, onFailedPresetCallback }
) => {
  if (buttonType === ButtonType.SIGNUP) {
    return signupButtonStructure
  }
  return (
    (await getComponentFromPreset(ROLE_SUBMIT_BUTTON, {
      presetKey,
      shouldFetchPresetsFromCDN,
      locale,
      onFailedPresetCallback,
    })) || submitButtonStructure
  )
}

export const getHiddenMessagePreset = async (
  messageType,
  { presetKey, shouldFetchPresetsFromCDN, locale, onFailedPresetCallback }
) => {
  if (messageType === MessageType.REGISTRATION) {
    return registrationFormMessageStructure
  }
  return (
    (await getComponentFromPreset(ROLE_MESSAGE, {
      presetKey,
      shouldFetchPresetsFromCDN,
      locale,
      onFailedPresetCallback,
    })) || hiddenMessageStructure
  )
}
