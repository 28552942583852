import * as _ from 'lodash'
import { ColorPalette, ComponentRef } from '../api-types'
import { isAnyField, undoable, withBi } from '../utils'
import CoreApi from '../core-api'
import {
  calcCommonStyle,
  calcCommonStyleGlobalDesign,
  getStyleValues,
  isFormStyle,
  commonStyles,
} from '../services/form-style-service'
import { getTheme } from '../preset/themes-service'
import { customStyleHandlers, getThemeOld } from '../preset/preset-styles'
import { FormAlphaStyle, FormStyle, Theme } from '../../../constants/form-style'
import { innerText } from '../../../utils/utils'
import { EVENTS } from '../../../constants/bi'
import { roleDesignMapping } from '../manifests/global-design-manifest'
import { ROLE_FORM } from '../../../constants/roles'
import { COMPONENT_TYPES } from '../preset/fields/component-types'

const paletteToMatrix = palette => {
  const result = [[], [], [], [], []]

  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 5; j++) {
      result[i][j] = palette[`color_${i * 5 + j + 11}`]
    }
  }

  return result
}
export default class StyleApi {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private biLogger: any
  private experiments: any

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateFieldsStyle(
    componentRef: ComponentRef,
    {
      styleName,
      newStyleValue,
      commonStyles,
    }: { styleName: FormStyle | FormAlphaStyle; newStyleValue: string; commonStyles: object },
    _biData
  ) {
    let fields
    if (isFormStyle(styleName)) {
      fields = [{ componentRef }]
    } else {
      fields = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
        allFieldsTypes: true,
      })
    }

    return Promise.all(
      fields.map(({ componentRef: fieldRef }) =>
        this._updateCompStyle(fieldRef, { styleName, newStyleValue, commonStyles })
      )
    )
  }

  public async getColorsPalette(): Promise<ColorPalette> {
    const palette = await this.boundEditorSDK.theme.colors.getAll()
    return { colorsPalette: paletteToMatrix(palette), colors: palette }
  }

  public openColorPicker(options, onColorChange) {
    return this.boundEditorSDK.editor.openColorPicker(options, onColorChange)
  }

  public getFontsOptions() {
    return this.boundEditorSDK.fonts.getFontsOptions()
  }

  public getThemedFonts() {
    return this.boundEditorSDK.theme.fonts.getMap()
  }

  public async getFieldsCommonStylesGlobalDesign(
    componentRef: ComponentRef
  ): Promise<commonStyles> {
    const compStyle = await this.boundEditorSDK.components.style.get({ componentRef })
    const formStyle = compStyle || { style: { properties: {} } }

    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const childrenRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const fieldsTypes = await Promise.all(
      childrenRefs.map(async child => {
        const type = await this.boundEditorSDK.components.getType({ componentRef: child })
        return { type }
      })
    )
    const fieldsStyleAndConnections = await this.boundEditorSDK.components.get({
      componentRefs: childrenRefs,
      properties: ['style', 'connections'],
    })
    const children = _.merge(fieldsTypes, fieldsStyleAndConnections)
    const fields = _.flatMap(children, ({ connections, style, type }) => {
      const fieldRole = _.get(connections, '[0].role')
      return isAnyField(fieldRole)
        ? {
            style: _.get(style, 'style.properties'),
            designMapping: _.get(roleDesignMapping, [fieldRole, type]) || [],
          }
        : []
    })

    return calcCommonStyleGlobalDesign([
      ...fields,
      {
        style: formStyle,
        designMapping: roleDesignMapping[ROLE_FORM][COMPONENT_TYPES.FORM_CONTAINER],
      },
    ])
  }

  /* deprecated should be deleted when merging specs.cx.FormBuilderGlobalDesign */
  public async getFieldsCommonStyles(componentRef: ComponentRef) {
    const {
      style: { properties } = { properties: {} },
    } = await this.boundEditorSDK.components.style.get({
      componentRef,
    })
    const form = { style: properties }

    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const childrenRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const children = await this.boundEditorSDK.components.get({
      componentRefs: childrenRefs,
      properties: ['style', 'connections'],
    })
    const fields = _.flatMap(children, ({ connections, style }) => {
      const fieldRole = _.get(connections, '[0].role')
      return isAnyField(fieldRole)
        ? {
            style: _.get(style, 'style.properties'),
          }
        : []
    })
    return calcCommonStyle(form, fields)
  }
  /* ****************************************************************************** */

  private async _updateThemeStyle(componentRef: ComponentRef, style, customStyleHandler) {
    if (!style) {
      return
    }
    if (!_.isString(style)) {
      return this.boundEditorSDK.components.style.update({ componentRef, style })
    }

    const { text } = await this.boundEditorSDK.components.data.get({ componentRef })
    const newText = customStyleHandler
      ? customStyleHandler(style, text)
      : _.replace(style, 'TITLE', innerText(text))

    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { text: newText },
    })
  }

  private _updateThemeProps(componentRef: ComponentRef, props) {
    if (!props) {
      return
    }
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props,
    })
  }

  private _updateThemeData(componentRef: ComponentRef, data) {
    if (!data) {
      return
    }
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data,
    })
  }

  public async getTheme(componentRef: ComponentRef) {
    const formConnection = await this.coreApi.getComponentConnection(componentRef)
    return _.get(formConnection, 'config.theme')
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateTheme(componentRef: ComponentRef, theme: Theme, _biData) {
    const shouldFetchThemesFromCDN = this.experiments.enabled(
      'specs.cx.FormBuilderFetchThemesFromCDN'
    )

    if (await this.coreApi.isAppWidget(componentRef)) {
      componentRef = await this.coreApi.getFormContainerOfAppWidget(componentRef)
    }

    await this.coreApi.setComponentConnection(componentRef, { theme })
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const children = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    const { stylesByRole, propsByRole, dataByRole } = shouldFetchThemesFromCDN
      ? await getTheme(theme)
      : getThemeOld(theme)

    if (_.isEmpty(stylesByRole)) {
      if (shouldFetchThemesFromCDN) {
        await this.coreApi.logFetchThemesFailed(
          componentRef,
          `theme ${theme} not found in resources`
        )
      } else {
        await this.coreApi.logFetchThemesFailed(componentRef, `theme ${theme} not found in app`)
      }
    }

    return Promise.all(
      _.map(children, async (childRef: ComponentRef) => {
        const { role } = await this.coreApi.getComponentConnection(childRef)

        return Promise.all([
          this._updateThemeProps(childRef, propsByRole[role]),
          this._updateThemeData(childRef, dataByRole[role]),
          this._updateThemeStyle(childRef, stylesByRole[role], customStyleHandlers[role]),
        ])
      })
    )
  }

  public async updateComponentTheme(componentRef, theme) {
    const shouldFetchThemesFromCDN = this.experiments.enabled(
      'specs.cx.FormBuilderFetchThemesFromCDN'
    )
    const { role } = await this.coreApi.getComponentConnection(componentRef)
    const { stylesByRole, propsByRole, dataByRole } = shouldFetchThemesFromCDN
      ? await getTheme(theme)
      : getThemeOld(theme)

    return Promise.all([
      this._updateThemeProps(componentRef, propsByRole[role]),
      this._updateThemeData(componentRef, dataByRole[role]),
      this._updateThemeStyle(componentRef, stylesByRole[role], customStyleHandlers[role]),
    ])
  }

  private async _updateCompStyle(
    componentRef: ComponentRef,
    { styleName, newStyleValue, commonStyles }
  ) {
    const {
      style: { properties } = { properties: {} },
    } = await this.boundEditorSDK.components.style.get({
      componentRef,
    })
    return this.boundEditorSDK.components.style.update({
      componentRef,
      style: _.merge({}, properties, getStyleValues(styleName, newStyleValue, commonStyles)),
    })
  }
}
