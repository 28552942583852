export enum FormStyle {
  FORM_BG_COLOR = 'FORM_BG_COLOR',
  INPUT_BG_COLOR = 'INPUT_BG_COLOR',
  ALPHA_INPUT_BG_COLOR = 'alpha-INPUT_BG_COLOR',
  INPUT_BORDER_COLOR = 'INPUT_BORDER_COLOR',
  ALPHA_INPUT_BORDER_COLOR = 'alpha-INPUT_BORDER_COLOR',
  TEXT_COLOR = 'TEXT_COLOR',
  FONT = 'FONT',
  BORDER_WIDTH = 'BORDER_WIDTH',
  BORDER_RADIUS = 'BORDER_RADIUS',
}

export enum FormAlphaStyle {
  ALPHA_FORM_BG_COLOR = 'alpha-FORM_BG_COLOR',
  ALPHA_INPUT_BG_COLOR = 'alpha-INPUT_BG_COLOR',
  ALPHA_INPUT_BORDER_COLOR = 'alpha-INPUT_BORDER_COLOR',
}

export const FONT_STYLE_VALUE_PREFIX = 'normal normal normal 15px/1.4em '

export enum Theme {
  THEME01 = 'theme01',
  THEME02 = 'theme02',
  THEME03 = 'theme03',
  THEME04 = 'theme04',
  THEME05 = 'theme05',
  THEME06 = 'theme06',
  PRESET01 = 'preset01',
  PRESET02 = 'preset02',
  PRESET03 = 'preset03',
  PRESET04 = 'preset04',
  PRESET05 = 'preset05',
  PRESET06 = 'preset06',
  PRESET07 = 'preset07',
  PRESET08 = 'preset08',
  PRESET09 = 'preset09',
  PRESET10 = 'preset10',
  PRESET11 = 'preset11',
  PRESET12 = 'preset12',
  PRESET13 = 'preset13',
  PRESET14 = 'preset14',
  PRESET15 = 'preset15',
  PRESET16 = 'preset16',
  PRESET17 = 'preset17',
  PRESET18 = 'preset18',
  PRESET19 = 'preset19',
  PRESET20 = 'preset20',
  ADI_DARK_01 = 'adi-dark-01',
  ADI_DARK_02 = 'adi-dark-02',
  ADI_LIGHT_01 = 'adi-light-01',
  ADI_LIGHT_02 = 'adi-light-02',
}
