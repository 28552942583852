import * as _ from 'lodash'
import * as theme01 from '../../../assets/components/theme01.json'
import * as theme02 from '../../../assets/components/theme02.json'
import * as theme03 from '../../../assets/components/theme03.json'
import * as theme04 from '../../../assets/components/theme04.json'
import * as theme05 from '../../../assets/components/theme05.json'
import * as theme06 from '../../../assets/components/theme06.json'
import { Theme } from '../../../constants/form-style'
import { innerText } from '../../../utils/utils'
import { ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG } from '../../../constants/roles'
import { FormPreset } from '../../../constants/form-types'

const handlePropertiesAsStyle = properties => {
  const propertiesSource = _.mapValues(properties, 'theme')

  return comp => {
    return _.merge({}, comp, { style: { style: { properties, propertiesSource } } })
  }
}

type CustomStyleHandler = { style?, text }

//TODO: Remove get_subscribers custom handler when we will complete form-builder-resources migration
export const customStyleHandlers = {
  [ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG]: ({ style, text }: CustomStyleHandler) =>
    _.replace(style, 'TITLE', text),
  [FormPreset.GET_SUBSCRIBERS]: ({ text }: CustomStyleHandler) => text,
}

export const applyStyle = (comp, theme, presetKey) => {
  const style = getThemeStyleOld(theme)[comp.role]

  if (!style) {
    return comp
  }

  if (!_.isString(style)) {
    return handlePropertiesAsStyle(style)(comp)
  }

  const customStyleHandler = customStyleHandlers[comp.role] || customStyleHandlers[presetKey]
  const text = comp.data.text
  const newText = customStyleHandler
    ? customStyleHandler({ style, text })
    : _.replace(style, 'TITLE', innerText(text))

  return _.merge({}, comp, { data: { text: newText } })
}

export const applyPropsAndData = (comp, theme) =>
  _.merge({}, comp, {
    props: getThemePropsOld(theme)[comp.role],
    data: getThemeDataOld(theme)[comp.role],
  })

const THEMES_STYLES = {
  [Theme.THEME01]: theme01,
  [Theme.THEME02]: theme02,
  [Theme.THEME03]: theme03,
  [Theme.THEME04]: theme04,
  [Theme.THEME05]: theme05,
  [Theme.THEME06]: theme06,
}

export const getThemeOld = (theme: Theme) => {
  const stylesByRole = getThemeStyleOld(theme)
  const propsByRole = getThemePropsOld(theme)
  const dataByRole = getThemeDataOld(theme)

  return { stylesByRole, propsByRole, dataByRole }
}
export const getThemeStyleOld = (theme: Theme) => _.get(THEMES_STYLES, [theme, 'style'], {})
export const getThemePropsOld = (theme: Theme) => _.get(THEMES_STYLES, [theme, 'props'], {})
export const getThemeDataOld = (theme: Theme) => _.get(THEMES_STYLES, [theme, 'data'], {})
